<template>
  <div class="reset-password">
    <Header />
    <div class="container">
      <h4 class="text-center title">Reset Your Password</h4>
      <v-form @submit="onSubmit">
        <div class="my-4" v-if="errors.length">
          <b class="error">Please correct the following error(s):</b>
          <ul>
            <li v-for="error in errors" v-bind:key="error">{{ error }}</li>
          </ul>
        </div>
        <div class="my-4" v-if="tokenErrors.length">
          <ul>
            <li v-for="error in tokenErrors" v-bind:key="error">{{ error }}</li>
          </ul>
          <h5 class="text-center">
            <a href="/reset-password-request">Request a new token</a>
          </h5>
        </div>

        <div class="mt-3 password-rules">
          <h6>Password must:</h6>
          <ul>
            <li>have a minimum length of 8 characters</li>
          </ul>
        </div>

          <v-text-field
            type="password"
            id="inputPassword"
            v-model="form.password"
            required
            placeholder="Enter password"
              label="Your New Password:"
          ></v-text-field>
          <v-text-field
            class="mt-3"
            type="password"
            id="inputPasswordConfirm"
            v-model="form.passwordConfirm"
            required
            placeholder="Enter password Again"
          ></v-text-field>

        <v-btn type="submit" color="primary" >Set Password</v-btn>
      </v-form>
    </div>
  </div>
</template>

<style scoped lang="scss">
.container {
  max-width: 500px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  padding: 25px;
  margin: 30px auto;
  background-color: white;
}
.title a {
  color: #00abc2;
}
.error{
  color: red;
}
.password-rules ul li{
  font-size: 14px;
}
</style>

<script>
import RESET_PASSWORD from '@/graphql/ResetPassword.gql';
import Header from '@/components/Header.vue';
import router from '@/router';

export default {
  components: { Header },
  data() {
    return {
      errors: [],
      tokenErrors: [],
      form: {
        password: '',
        passwordConfirm: '',
      },
      show: true,
    };
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();

      this.errors = [];
      this.tokenErrors = [];
      if (this.form.password === this.form.passwordConfirm) {
        const { resetPasswordToken } = this.$route.query;
        try {
          const resetPasswordResult = await this.$apollo.mutate({
            mutation: RESET_PASSWORD,
            variables: {
              newPassword: this.form.password,
              resetPasswordToken,
            },
          });
          if (
            resetPasswordResult
            && resetPasswordResult.data.resetPassword.success
          ) {
            alert('Your Password Was Reset!');
            router.push({ name: 'signin' });
          } else {
            this.tokenErrors.push(
              'There was a problem resetting your password.',
            );
          }
        } catch (error) {
          this.errors.push(error.toString().replace('Error: GraphQL error: ', '')); // Vue doesn't like non-native types as keys, hence string
        }
      } else {
        this.errors.push('Passwords do not match');
      }
    },
  },
};
</script>
