<template>
  <v-container class="main-nav">


    <v-toolbar color="transparent" flat dark>
      <v-toolbar-title>
        <img id="logo" height="50px" src="assets/logo.png" />
      </v-toolbar-title>

      <div class="flex-grow-1"></div>

      <v-toolbar-items>
        <v-btn text class="active">Dashboard</v-btn>
        <v-btn text>Tickets</v-btn>
        <v-btn text>Calendar</v-btn>
        <v-btn text>Tasks</v-btn>
      </v-toolbar-items>

      <template v-if="$vuetify.breakpoint.smAndUp">
        <v-btn icon class=" ml-12">
          <v-icon>mdi-account-circle</v-icon>
        </v-btn>
      </template>
    </v-toolbar>
  </v-container>
</template>

<style  lang="scss">
  #logo {
    height: 40px;
    // margin-left: 25px;
    filter: brightness(0) invert(1);
  }
  .main-nav{
    .v-btn{
      .v-btn__content{
        font-weight: 600;
        // color: var(--v-secondary-base);
        color: white;
      }
    }
    .v-btn.active{
      .v-btn__content{
        position: relative;
        color: var(--v-secondary-base);
        // color: white;
        &:after{
          content: ""!important;
          background-color: var(--v-secondary-base);
          // background-color: white;
          width: 105%;
          height: 3px;
          border-radius: 4px;
          position: absolute;
          bottom: -50%;
          z-index: 9999;

        }
      }
    }
  }
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'Header',
  props: {
    showNav: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
});
</script>
